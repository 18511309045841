export const menuLinks = [
  {
    name: "HOME",
    href: "/",
    routeNames: ["index"],
  },
  {
    name: "PROFILE",
    href: "/profile",
    routeNames: [
      "profile",
      "profile-edit",
      "profile-id",
      "profile-upcoming-games",
    ],
  },
  {
    name: "GAME OPPORTUNITIES",
    href: "/game-opportunities",
    routeNames: ["game-opportunities", "game-opportunities-id"],
  },
  {
    name: "RIOTERS",
    href: "/rioters",
    routeNames: ["rioters", "rioters-id"],
  },
];
